import Notice1ImageKOR1 from '../assets/images/notice/notice_image_01.webp';
import Notice2ImageKOR1 from '../assets/images/notice/notice2_image_01.webp';
import Notice2ImageKOR2 from '../assets/images/notice/notice2_image_02.webp';
import Notice2ImageKOR3 from '../assets/images/notice/notice2_image_03.webp';

export const noticeData = [
    {
        id: "1",
        isnew: true,
        contents: {
            englishtitle: "🎨 Complete your artworks more attractively with the help of professional instructor's feedbacks at ARIA Illustration Academy! 🎨",
            japanesetitle: "🎨ARIAだけの特別なフィードバック権を通じて、専門家の助けを借りて皆さんの絵をより魅力的に完成させましょう！🎨",
            title: "새로운 기회! 피드백권 오픈 안내",
            period: "",
            date: "2024.04.08",
            status: "",
            content: {
                englishcontent: [
                    {
                        image: true,
                        content: Notice2ImageKOR1
                    },
                    {
                        image: false,
                        content: [
                            "🎨 Complete your artworks more attractively with the help of professional\ninstructor's feedbacks at ARIA Illustration Academy! 🎨",
                            `This is your chance to receive expert's delicate feedback\non your work with the "feedback ticket"`,
                            `💡 What can you do with the "feedback ticket"?\n\n1. Expert Advice: Receive one-on-one, real-time feedback\nwithin an hour from ARIA Academy's professional\ninstructors on your artwork.\n2. Flexible Learning: A flexible time setting for those\nwho find it difficult to participate in classes due to\na busy schedule or those who want to experience a class.\n3. Personalized Instruction: Receive substantial advice from instructors\nthat can elevate your artwork to the next level,\nincluding aspects of composition, color, and technique.`,
                        ]
                    },
                    {
                        image: true,
                        content: Notice2ImageKOR2
                    },
                    {
                        image: false,
                        content: [
                            "🚀 How to Use\n\n1. After viewing the instructors' curriculum on the ARIA website,\nplease contact us through the following methods :\nTwitter, Instagram, or Discord ticketing.\n(Please check the counseling guide on the website)\n2. After consulting with the academy for your desired time,\nplease purchase the feedback ticket.\n3. Get your one-time hourly feeback of your artwork with the help\nof your chosen instructor!",
                            "※Please note that the number of feedback tickets may be limited\ndue to the instructors' circumstances,\nand the period may be postponed depending on the schedule."
                        ]
                    },
                    {
                        image: true,
                        content: Notice2ImageKOR3
                    },
                ],
                japanesecontent: [
                    {
                        image: true,
                        content: Notice2ImageKOR1
                    },
                    {
                        image: false,
                        content: [
                            "🎨ARIAだけの特別なフィードバック権を通じて、\n専門家の助けを借りて皆さんの絵をより魅力的に完成させましょう！🎨",
                            "忙しい日常の中で、ARIAだけの特別なフィードバック権を使って、\n皆さんの作品に専門家の細やかなフィードバックを受けるチャンスです！",
                            "💡 フィードバック権とは？\n\n１．専門家のアドバイス: ARIAの専門講師陣から、皆さんの作品に対して\n1対1で1時間以内のリアルタイムフィードバックを受けることができます。\n2．柔軟な学習: 忙しいスケジュールで授業参加が難しい方や、\n授業を体験してみたい方のための学習ソリューションです。\n３．個人に合わせた指導: 絵の構成、色彩、技法など、\n皆さんの作品を一段階引き上げることができる\n講師の実質的なアドバイスを提供します。",
                        ]
                    },
                    {
                        image: true,
                        content: Notice2ImageKOR2
                    },
                    {
                        image: false,
                        content: [
                            "🚀 利用方法\n\n１．ARIAホームページで講師のカリキュラムを見た後、\nTwitter、Instagram、Discordチケットなど、\n様々な方法で相談してください。\n（ホームページの相談案内をご確認ください）\n２．希望の時間を相談した後、フィードバック権を購入してください。\n３．皆さんの絵が専門家の助けを借りて完成されるプロセスを\n体験してください！",
                            "※講師の事情により、フィードバック権の数量に限りがある場合があり、\nスケジュールによっては期間が延期されることがあります。"
                        ]
                    },
                    {
                        image: true,
                        content: Notice2ImageKOR3
                    },
                ],
                content: [
                    {
                        image: true,
                        content: Notice2ImageKOR1
                    },
                    {
                        image: false,
                        content: [
                            "🎨 ARIA 일러스트 학원만의 특별한 피드백권을 통해\n전문가의 도움으로 여러분들의 그림을 더욱더 매력적이게 완성하세요! 🎨",
                            "바쁜 일상 속에서 수업을 체험 해볼수 있는 ARIA 학원만의 특별한 피드백권으로\n여러분의 작품에 전문가의 섬세한 피드백을 받아 볼 수 있는 기회입니다!",
                            "💡 피드백권으로 무엇을 할 수 있나요?\n\n1.전문가의 조언: ARIA 학원의 전문 강사진에게 여러분의 작품에 대한 1:1 로\n1시간 이내의 실시간 피드백을 받으실 수 있습니다.\n2.유연한 학습: 바쁜 일정으로 수업 참여가 어렵거나 수업을 체험해 보고 싶으신 분들을 위한\n맞춤형 학습 솔루션입니다.\n3.개인 맞춤형 지도: 그림의 구성, 색감, 기법 등 여러분의 작품을 한 단계 끌어올릴 수 있는\n강사님의 실질적인 조언을 드립니다.",
                        ]
                    },
                    {
                        image: true,
                        content: Notice2ImageKOR2
                    },
                    {
                        image: false,
                        content: [
                            "🚀 이용 방법\n\n1.ARIA 홈페이지의 강사님들의 커리큘럼을 보신 후\n트위터,인스타, 디스코드 티켓팅 다양한 방법으로 상담 연락 주세요.\n(홈페이지 상담 안내를 확인해주세요)\n2.원하는 시간을 학원과 상담후 피드백권을 구매해주세요.\n3.여러분의 그림이 전문가의 도음을 통해 완성되는 과정을 경험하세요!",
                            "※강사님의 사정에 따라 피드백권 수량이 한정될수 있으며\n일정에 따라 기간이 미뤄질수 있습니다.",
                        ]
                    },
                    {
                        image: true,
                        content: Notice2ImageKOR3
                    },
                ],
            },
        },
        status: "Notice",
    },
    {
        id: "2",
        isnew: true,
        contents: {
            englishtitle: "🌟 Special Open Anniversary Discount Event! 🌟",
            japanesetitle: "🌟 オープン記念スペシャル割引キャンペーン！ 🌟",
            title: "새로운 시즌패스(6개월권) 출시 안내",
            period: "",
            date: "2024.03.17",
            status: "season pass",
            content: {
                englishcontent: [
                    {
                        image: true,
                        content: Notice1ImageKOR1
                    },
                    {
                        image: false,
                        content: [
                            "🌟 Introducing new season pass(six-month membership)! 🌟",
                            "The perfect opportunity for your growth and development,\nget started now! We support your passion and dedication.\nWe present a specially designed Season Pass (six-month membership)\nThis season pass gives you everything you need to learn new skills,\ndevelop yourself, and achieve your goals.",
                            "💡 Season Pass Special Benefits\n\nSeason Pass’s special benefits are as follows:\n\n🌟 Additional discount: 10% off the normal price.\n🎥 Flexible Course Conditions: Provides flexibility to delay your class twice\nwithout any disadvantages if you notify in advance.\n\nMake and implement a study plan economically\nand temporarily through the season pass.",
                            "💡 How to participate in the season pass\n\nIf you want to participate, please follow these steps:\n\n1. Visit the ARIA website: Access the official ARIA website\n(https://www.aria-academy.com/) and\ncheck the course registration processwith Discord.\n2. Select the mentor you want,\nand please inform your intention to take the course as a season pass\nat Discord ticket or Instagram or X(Twitter) DM.\n3. Once you complete the payment,\nyou can take the course for 6 months at a discounted price.",
                        ]
                    },
                ],
                japanesecontent: [
                    {
                        image: true,
                        content: Notice1ImageKOR1
                    },
                    {
                        image: false,
                        content: [
                            "🌟新しく披露するシーズンパス(6ヶ月券)と一緒に新しい学びを始めましょう！🌟",
                            "皆様の成長と発展のための完璧な機会、今すぐ始めましょう！\nARIAは皆様の情熱と献身を支持し、\nそのために特別に用意されたシーズンパス(6ヶ月券)を披露します。\nシーズンパスは、皆様が新しい技術を学び、自己啓発を成し遂げ、目標を達成するために必要なすべてのものを提供します。",
                            "💡シーズンパス特別特典\n\nシーズンパスを通じて次のような特別な特典をご利用いただけます:\n\n🌟追加割引:通常価格に比べて10%割引された価格でレッスンを提供します。\n\n🎥柔軟な受講条件:事前にお知らせいただいた場合、\n不利益なく2回授業を先送りできる柔軟性を提供します。\n\nシーズンパスを通じて経済的にも時間的にも\n余裕を持って学習計画を立てましょう。",
                            "💡シーズンパス参加方法\n\n参加をご希望の方は、次のステップに従ってください:\n\n1. ARIAホームページ訪問:\nARIA公式ホームページ(https://www.aria-academy.com/)に\nアクセスし、ディスコードで受講申請手続きを確認します。\n2. 希望する講師を選択し、シーズンパスで受講したいという意思を\nディスコードチケットまたはインスタグラム、\nX(ツイッター)DMで伝達してください。\n3. 決済を完了すると、\n通常価格に比べ10%割引された価格で\n6ヶ月間受講が可能です。"
                        ]
                    },
                ],
                content: [
                    {
                        image: true,
                        content: Notice1ImageKOR1
                    },
                    {
                        image: false,
                        content: [
                            "🌟 새롭게 선보이는 시즌패스(6개월권)와 함께 새로운 배움을 시작하세요! 🌟",
                            "여러분의 성장과 발전을 위한 완벽한기회, 바로 지금 시작하세요!\nARIA는 여러분의 열정과 헌신을 지지하며,\n이를 위해 특별히 마련된 시즌패스(6개월권)를 선보입니다.\n이 시즌패스는 여러분이 새로운 기술을 배우고, 자기계발을 이루며,\n목표를 달성하는 데 필요한 모든 것을 제공합니다.",
                            "💡 시즌패스 특별 혜택\n\n시즌패스를 통해 다음과 같은 특별한 혜택을 누리실 수 있습니다:\n\n🌟 할인 혜택: 정상 가격 대비 10% 할인된 가격으로 강의를 제공합니다.\n\n🎥 유연한 수강 조건: 사전에 의사 사정을 통보하실 경우,\n불이익 없이 2번 수업을 미룰 수 있는 유연성을 제공합니다.\n\n시즌패스를 통해 경제적으로도, 시간적으로도\n여유롭게 학습 계획을 세우고 실행하세요.",
                            "💡 시즌패스 참여 방법\n\n참여를 원하시는 분들은 다음 단계를 따라 주세요:\n\n1. ARIA 홈페이지 방문: ARIA 공식 홈페이지(https://www.aria-academy.com/)에\n접속하여 디스코드로 수강 신청 절차를 확인하세요.\n2. 원하는 강사님을 선택하고, 시즌패스로 수강하고 싶다는 의사를\n디스코드 티켓 또는 인스타그램, X(트위터) DM으로 전달해 주세요.\n3. 결제를 완료하시면, 정상 가격 대비 10% 추가 할인된 가격으로\n6개월 간 수강이 가능합니다.",
                        ]
                    },
                ],
            },
        },
        status: "Notice",
    },
];